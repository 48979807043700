import * as types from "./actionTypes";
import axios from "axios";




export const seeAddedStocks = (payload) => (dispatch) => {
  dispatch({ type: types.GET_ADDEDSTOCKS_REQUEST });
  return axios
    .get(`https://bharatapi.exploreanddo.com/api/get-stock-details`, payload)
    .then((res) => {
      dispatch({ type: types.GET_ADDEDSTOCKS_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_ADDEDSTOCKS_FAILURE, payload: err });
    });
};

export const editStockDetails = (id, payload) => (dispatch) => {
  dispatch({ type: types.EDIT_STOCK_REQUEST });
  return axios
    .put(`https://bharatapi.exploreanddo.com/api/edit-stock-details/${id}`, payload)
    .then((res) => {
      dispatch({ type: types.EDIT_STOCK_SUCCESS, payload: res.data.data });
      //console.log("taskstatus", payload)
    })
    .catch((err) => {
      dispatch({ type: types.EDIT_STOCK_FAILURE, payload: err });
    });
};

export const seeSalesStocks = (payload) => (dispatch) => {
  dispatch({ type: types.GET_SALES_STOCKS_REQUEST });
  return axios
    .get(`https://bharatapi.exploreanddo.com/api/get-sales-details`, payload)
    .then((res) => {
      dispatch({ type: types.GET_SALES_STOCKS_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_SALES_STOCKS_FAILURE, payload: err });
    });
};


export const getInvoiceDteails = (payload) => (dispatch) => {
  dispatch({ type: types.GET_INVOICE_REQUEST });
  return axios
    .get(`https://bharatapi.exploreanddo.com/api/get-invoice-details`, payload)
    .then((res) => {
      dispatch({ type: types.GET_INVOICE_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_INVOICE_FAILURE, payload: err });
    });
};

export const getReturnedItems = (payload) => (dispatch) => {
  dispatch({ type: types.GET_RETURNED_REQUEST });
  return axios
    .get(`https://bharatapi.exploreanddo.com/api/get-return-details`, payload)
    .then((res) => {
      dispatch({ type: types.GET_RETURNED_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({ type: types.GET_RETURNED_FAILURE, payload: err });
    });
};

//https://bharatapi.exploreanddo.com/api/delete-stock-details/{id}