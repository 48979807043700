import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";

const AddStockPage = () => {
  const toast = useToast();

  const [name, setName] = useState("");
  const [order_id, setOrderId] = useState("");
  const [description, setDescription] = useState("");
  const [rate, setRate] = useState("");
  const [purchase_rate, setPurchaseRate] = useState("");
  const [stock_date, setstockDate] = useState("");
  const [quantity, setQuantity] = useState("");
  const [rack_pos, setRackPosition] = useState("");
  const [gst_in_percent, setGstPercent] = useState("");

  const handleAddStocks = () => {
    const apiUrl = "https://bharatapi.exploreanddo.com/api/save-stock-details";

    const formData = {
      name,
      order_id,
      description,
      purchase_rate,
      rate,
      stock_date,
      quantity,
      rack_pos,
      gst_in_percent,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("POST request successful:", data);
        if (data.success) {
          toast({
            position: "top",
            title: data.message,
            description: "The stock has been added to Stock Control",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            position: "top",
            title: data.message,
            description: `${data.message} ${
              data.data.order_id ? `${data.data.order_id[0]}` : ""
            } All Fields are required`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }

        // Handle the response data as needed
        setName("");
        setDescription("");
        setQuantity("");
        setOrderId("");
        setstockDate("");
        setRackPosition("");
        setGstPercent("");
        setRate("");
        setPurchaseRate("");
      })
      .catch((error) => {
        console.error("Error during POST request:", error);

        toast({
          position: "top",
          title: "Error",
          description: "An error occurred while adding the stock.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        // Handle errors
      });
  };

  return (
    <Flex>
      <Sidebar />
      <Box flex="1" p="4">
        <Navbar />
        <Flex
          minHeight="90vh"
          alignItems="center"
          justifyContent="center"
          px={{ base: 4, sm: 6, md: 8 }}
        >
          <VStack spacing={4} align="stretch" w="100%" maxW="md">
            <Box p={8} borderWidth={1} borderRadius={8} boxShadow="lg" w="100%">
              <FormControl id="stock_date">
                <FormLabel mt={1} fontSize={"sm"}>
                  Stock Date
                </FormLabel>
                <Input
                  type="date"
                  placeholder="Stock Date"
                  value={stock_date}
                  onChange={(e) => setstockDate(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl id="name">
                <FormLabel fontSize={"sm"}>Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl id="order_id">
                <FormLabel mt={1} fontSize={"sm"}>
                  HSN/SAC
                </FormLabel>
                <Input
                  type="text"
                  placeholder="HSN/SAC"
                  value={order_id}
                  onChange={(e) => setOrderId(e.target.value)}
                  required
                />
              </FormControl>
            

              <FormControl id="quantity">
                <FormLabel mt={1} fontSize={"sm"}>
                  Quantity
                </FormLabel>
                <Input
                  type="text"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl id="rate">
                <FormLabel mt={1} fontSize={"sm"}>
                  Purchase Rate
                </FormLabel>
                <Input
                  type="text"
                  placeholder="Purchase Rate"
                  value={purchase_rate}
                  onChange={(e) => setPurchaseRate(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl id="rate">
                <FormLabel mt={1} fontSize={"sm"}>
                  Sale Rate
                </FormLabel>
                <Input
                  type="text"
                  placeholder=" Sale Rate"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  required
                />
              </FormControl>

              <FormControl id="rack_pos">
                <FormLabel mt={1} fontSize={"sm"}>
                  Rack Position
                </FormLabel>
                <Input
                  type="text"
                  placeholder="Rack Position"
                  value={rack_pos}
                  onChange={(e) => setRackPosition(e.target.value)}
                  required
                />
              </FormControl>
              <Button
                mt={4}
                onClick={handleAddStocks}
                bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                w="100%"
              >
                Add Stock
              </Button>
            </Box>
          </VStack>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AddStockPage;
