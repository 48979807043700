import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  PopoverFooter,
  ButtonGroup,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin2Line } from "react-icons/ri";
import { editStockDetails, seeAddedStocks } from "../Redux/app/action";
import { Pagination } from "../Components/Pagination";
import { format } from "date-fns";

const AddingStocks = () => {
  const InputStyle = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "green",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };
  const dispatch = useDispatch();

  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();

  const btnRef = useRef();

  const toast = useToast();

  useEffect(() => {
    dispatch(seeAddedStocks());
  }, [dispatch]);

  const addedStocks = useSelector((state) => state.AppReducer.addedStocks);
  //console.log("addedStocks", addedStocks);

  const [deleting, setDeleting] = useState(false);
  const handleDeleteStock = async (id) => {
    setDeleting(true);

    try {
      const apiEndpoint = `https://bharatapi.exploreanddo.com/api/delete-stock-details/${id}`;
      const response = await fetch(apiEndpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error deleting stock with ID ${id}`);
      }

      const data = await response.json();
      //console.log('Stock deleted successfully:', data);

      toast({
        position: "top",
        title: "Stock Deleted Successfully",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error deleting stock:", error);

      toast({
        position: "top",
        title: "Stock Deleting Failed",
        description:
          error.message || "An error occurred while deleting the stock.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setDeleting(false);
    }
  };

  // Handling Task Delete End Here

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  let perPage = 10;

  // Check if taskData is an array, otherwise initialize it as an empty array
  let stockDataArray = Array.isArray(addedStocks) ? addedStocks : [addedStocks];

  // Calculate total pages based on the filtered data
  let totalPage = Math.ceil(stockDataArray.length / perPage);
  let end = page * perPage;
  let start = end - perPage;

  // Apply filtering on the taskDataArray and handle non-array cases
  let filteredData = Array.isArray(stockDataArray)
    ? stockDataArray.filter(
        (el) =>
          (el.name &&
            el.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.description &&
            el.description.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.rack_pos &&
            el.rack_pos.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.order_id &&
            el.order_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.stock_date &&
            el.stock_date.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.gst_in_percent &&
            el.gst_in_percent.toString().includes(searchQuery.toLowerCase()))
      )
    : [stockDataArray];

  // Apply pagination to the filteredData
  let paginationData = filteredData.slice(start, end);

  //console.log("paginationData", paginationData);

  // Handling Pagination End Here

  const [hoveredIdTitle, setHoveredIdTitle] = useState(null);

  const handleHoverTitle = (id) => {
    setHoveredIdTitle(id);
  };

  const handleMouseLeaveTitle = () => {
    setHoveredIdTitle(null);
  };

  const [selectedItem, setSelectedItem] = useState(null);
  //console.log("selected", selectedItem);
  const [formData, setFormData] = useState({
    name: "",
    quantity: "",
    rate: "",
    purchase_rate: "",
    rack_pos: "",
    order_id: "",
    stock_date: "",
    // description: "",
  });

  const handleUpdateStockDetail = async (id) => {
    try {
      await dispatch(editStockDetails(selectedItem.id, formData));
      setSelectedItem(null);
      setFormData({
        name: "",
        quantity: "",
        rate: "",
        purchase_rate: "",
        rack_pos: "",
        order_id: "",
        stock_date: "",
        // description: "",
      });

      toast({
        position: "top",
        title: "Stock Updated Successfully",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Updating Stock",
        description: "An error occurred while updating the Stock.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (el) => {
    setSelectedItem(el);
    setFormData({
      name: el.name,
      quantity: el.quantity,
      rate: el.rate,
      purchase_rate: el.purchase_rate,
      rack_pos: el.rack_pos,
      order_id: el.order_id,
      stock_date: el.stock_date,
      // description: el.description,
    });
  };

  const [cart, setCart] = useState([]);
  //console.log("cart", cart);

  const addToCart = (selectedProduct) => {
    const existingItem = cart.find((item) => item.id === selectedProduct.id);

    if (existingItem) {
      toast({
        position: "top",
        title: "Item Already in Cart",
        description: `${selectedProduct.name} is already in the cart.`,
        status: "info",
        duration: 3000,
        isClosable: true,
      });

      setCart((prevCart) =>
        prevCart.map((item) =>
          item.id === selectedProduct.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );
    } else {
      setCart((prevCart) => [...prevCart, { ...selectedProduct }]);
      toast({
        position: "top",
        title: "Item Added To Cart",
        description: `${selectedProduct.name} has been added to the cart.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <Flex>
      <Box pl={{ base: "none", md: "10" }} w={{ base: "100%", md: "100%" }}>
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Stocks Control
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">Stocks Control</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justifyContent={"space-between"} gap={3}>
            <Input
              htmlSize={20}
              width={500}
              placeholder="Search By Order Id, Name, Description"
              focusBorderColor="green.400"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Flex>
        </Flex>
        <Box>
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Add to cart
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Stock Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Name
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    HSN/SAC
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Quantity
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Purchase Rate
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Sale Rate
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Rack Position
                  </Th>
                  {/* <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    GST in %
                  </Th> */}
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Action
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationData.length > 0 &&
                  paginationData
                    ?.filter((el) => el.quantity > 0)
                    .map((el) => {
                      return (
                        <Tr key={el.id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              onClick={() => addToCart(el)}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              +
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {format(new Date(el.stock_date), "dd-MM-yyyy")}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.name}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.order_id}
                            </Button>
                          </Td>

                          {/* <Td
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Tooltip
                              label={el.description}
                              isOpen={hoveredIdTitle === el.id}
                              placement="bottom"
                            >
                              <Button
                                size={"xs"}
                                onMouseEnter={() => handleHoverTitle(el.id)}
                                onMouseLeave={handleMouseLeaveTitle}
                                // width={hoveredIdTitle === el._id ? 'auto' : '100px'}
                                bgGradient="linear(to-r, teal.300,  teal.100)"
                                _hover={{
                                  bgGradient: "linear(to-r, teal.100,  teal.400)",
                                }}
                              >
                                {el.description.slice(0, 15)}
                              </Button>
                            </Tooltip>
                          </Td> */}

                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.quantity}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              Rs {el.purchase_rate ? el.purchase_rate : "N/A"}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              Rs {el.rate}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.rack_pos}
                            </Button>
                          </Td>
                          {/* <Td style={{ textAlign: "center" }}>
                            <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}>{el.gst_in_percent}%</Button>
                          </Td> */}

                          <Td style={{ textAlign: "center" }}>
                            <Flex justifyContent={"space-around"} gap={2}>
                              <Button
                                ref={btnRef}
                                onClick={() => {
                                  onSidebarOpen();
                                  handleEdit(el);
                                }}
                                size={"xs"}
                                bgGradient="linear(to-r, teal.300,  teal.100)"
                                _hover={{
                                  bgGradient:
                                    "linear(to-r, teal.100,  teal.400)",
                                }}
                              >
                                <FiEdit />
                              </Button>

                              <Popover>
                                <PopoverTrigger>
                                  <Button
                                    size={"xs"}
                                    color="red"
                                    bgGradient="linear(to-r, teal.300,  teal.100)"
                                    _hover={{
                                      bgGradient:
                                        "linear(to-r, teal.100,  teal.400)",
                                    }}
                                  >
                                    <RiDeleteBin2Line />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverBody>
                                    Are you sure you want to delete?
                                  </PopoverBody>
                                  <PopoverFooter
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap={2}
                                  >
                                    <ButtonGroup size="xs">
                                      <Button
                                        color="green"
                                        _hover={{
                                          color: "white",
                                          bgColor: "green",
                                        }}
                                        onClick={() => handleDeleteStock(el.id)}
                                        fontWeight="bold"
                                        isLoading={deleting}
                                      >
                                        Yes
                                      </Button>
                                    </ButtonGroup>
                                  </PopoverFooter>
                                </PopoverContent>
                              </Popover>
                            </Flex>
                          </Td>
                        </Tr>
                      );
                    })}
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Matches Found
                </Alert>
              )}
            </Box>
            {selectedItem && (
              <Box>
                <Drawer
                  isOpen={isSidebarOpen}
                  onClose={onSidebarClose}
                  placement="right"
                  finalFocusRef={btnRef}
                  size={"sm"}
                >
                  <form>
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />
                      <DrawerHeader>Edit Stock Details</DrawerHeader>
                      <DrawerBody>
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="date"
                            value={formData.stock_date}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                stock_date: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Update Date</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            value={formData.name}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                name: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Name</Box>
                        </Box>

                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            value={formData.order_id}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                order_id: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>HSN/SAC</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            value={formData.quantity}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                quantity: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Quantity</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            value={formData.purchase_rate}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                purchase_rate: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Purchase Rate</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            value={formData.rate}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                rate: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Sale Rate</Box>
                        </Box>
                        <br />

                        <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            value={formData.rack_pos}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                rack_pos: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Rack Position</Box>
                        </Box>
                        <br />
                        {/* <Box position="relative">
                          <Input
                            {...InputStyle}
                            type="text"
                            value={formData.gst_in_percent}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                gst_in_percent: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>GST in %</Box>
                        </Box> */}
                      </DrawerBody>
                      <DrawerFooter>
                        <Button
                          onClick={handleUpdateStockDetail}
                          bgGradient="linear(to-r, teal.300,  teal.100)"
                          _hover={{
                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                          }}
                        >
                          Update Stocks
                        </Button>
                      </DrawerFooter>
                    </DrawerContent>
                  </form>
                </Drawer>
              </Box>
            )}
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default AddingStocks;
