// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Flex,
//   IconButton,
//   Menu,
//   MenuButton,
//   MenuItem,
//   MenuList,
//   Avatar,
//   useToast,
//   Spacer,
//   Input,
//   Button,
//   Text,
// } from "@chakra-ui/react";
// import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";

// const Navbar = () => {
//   const handleNotificationClick = () => {
//     // Implement notification click logic here
//   };

//   const handleProfileClick = () => {
//     navigate("/");
//   };

//   const navigate = useNavigate();
//   const toast = useToast();

//   const handleSignOut = () => {
//     localStorage.removeItem('email');
//     localStorage.removeItem('password');
//     localStorage.removeItem('state');
//     toast({
//       position: "top",
//       title: `Successfully Logged Out`,
//       description: "Successfully Logged Out",
//       status: "success",
//       duration: 3000,
//       isClosable: true,
//     });
//     navigate("/");
//   };

//   const gotoAddStockPage = () => {
//     navigate("/addstocks");
//   };
//   const gotoGenerateInvoicePage = () => {
//     navigate("/generateinvoice");
//   };
  
//   const [stocks, setStocks] = useState([]);
//   const [stockData, setStockData] = useState([]);
//   const [showBox, setShowBox] = useState(false);
//   const [value, setValue] = useState("");

//   //console.log("stocks", stocks);

//   useEffect(() => {
//     axios
//       .get("https://bharatapi.exploreanddo.com/api/get-stock-details")
//       .then((res) => {
//         setStocks(res.data.data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, []);

//   const handleFilter = (e) => {
//     setValue(e.target.value);
//     const filterResult =
//       stocks &&
//       stocks.filter((item) =>
//         item.name.toLowerCase().includes(e.target.value.toLowerCase())
//       );
//     setStockData(filterResult);
//   };

//   useEffect(() => {
//     if (value === "") {
//       setShowBox(false);
//     }
//     if (value !== "") {
//       setShowBox(true);
//     }
//   }, [value]);

//   return (
//     <Flex
//       as="nav"
//       align="center"
//       justify="space-between"
//       paddingX={6}
//       paddingY={4}
//       color="teal"
//       boxShadow="sm"
//       zIndex="sticky"
//       borderRadius={10}
//     >
//       <Flex align="center" w="75%">
//         <Button
//           onClick={gotoAddStockPage}
//           bgGradient="linear(to-r, teal.300,  teal.100)"
//           _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
//           mb={{ base: 2, md: 0 }}
//         >
//           Add Stock
//         </Button>
//         <Input
//           type="text"
//           placeholder="Search Stocks..."
//           flex="1"
//           ml={{ base: 0, md: 4 }}
//           onChange={handleFilter}
//           focusBorderColor="green.400"
//         />
//         <Button
//           onClick={gotoGenerateInvoicePage}
//           bgGradient="linear(to-r, teal.300,  teal.100)"
//           _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
//           mb={{ base: 2, md: 0 }}
//           ml={{ base: 0, md: 4 }}
//         >
//           Invoice
//         </Button>
//         <Box display={{ base: "none", md: "block" }} >
//           {showBox && (
//             <Box
//               position="absolute"
//               top="70px"
//               left="300px"
//               right="0"
//               border="1px solid purple"
//               borderRadius={5}
//               backgroundColor="white"
//               overflow="hidden"
//               zIndex="999"
//               w="60%"
//             >
//               {stockData &&
//                 stockData.map((item) => (
//                   <Box key={item.id} p={2}>
//                     <Link to={`/stockcontrol`}>
//                       <Flex justifyContent="space-between">
//                         <Box textAlign="start">
//                           <Text fontWeight="semibold" color="purple">
//                             {item.name}
//                           </Text>
//                           <Text fontSize="sm">Quantity {item.quantity}</Text>
//                         </Box>
//                         <Box>
//                           <Text fontSize="sm">Order Id : {item.order_id}</Text>
//                           <Text fontSize="sm">
//                             Rack Position : {item.rack_pos}
//                           </Text>
//                         </Box>
//                       </Flex>
//                     </Link>
//                   </Box>
//                 ))}
//             </Box>
//           )}
//         </Box>
//       </Flex>
//       <Spacer />
//       <Box>
//         <IconButton
//           icon={<BellIcon />}
//           ml={2}
//           colorScheme="teal"
//           onClick={handleNotificationClick}
//           aria-label="Notifications"
//         />

//         <Menu>
//           <MenuButton
//             as={IconButton}
//             icon={
//               <Avatar
//                 size="sm"
//                 name="Admin"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-SQGQRq2XYAV7IP-YAjF16UYQ_eRGupVRK3Ke8m10Aw&s"
//               />
//             }
//             colorScheme="white"
//             ml={2}
//           />
//           <MenuList>
//             <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
//             <MenuItem onClick={handleSignOut}>Logout</MenuItem>
//           </MenuList>
//         </Menu>
//       </Box>
//     </Flex>
//   );
// };

// export default Navbar;



import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
  useToast,
  Spacer,
  Input,
  Button,
  Text,
} from "@chakra-ui/react";
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Navbar = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const handleNotificationClick = () => {
    // Implement notification click logic here
  };

  const handleForgetPassword = () => {
    navigate("/change-password")
  };



  const handleSignOut = () => {
    // localStorage.removeItem('email');
    // localStorage.removeItem('password');
    localStorage.removeItem('state');
    localStorage.removeItem("token");
    toast({
      position: "top",
      title: `Successfully Logged Out`,
      description: "Successfully Logged Out",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    navigate("/");
  };

  const gotoAddStockPage = () => {
    navigate("/addstocks");
  };
  const gotoGenerateInvoicePage = () => {
    navigate("/generateinvoice");
  };
  
  const [stocks, setStocks] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [showBox, setShowBox] = useState(false);
  const [value, setValue] = useState("");

  //console.log("stocks", stocks);

  useEffect(() => {
    axios
      .get("https://bharatapi.exploreanddo.com/api/get-stock-details")
      .then((res) => {
        setStocks(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleFilter = (e) => {
    setValue(e.target.value);
    const filterResult =
      stocks &&
      stocks.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
    setStockData(filterResult);
  };

  useEffect(() => {
    if (value === "") {
      setShowBox(false);
    }
    if (value !== "") {
      setShowBox(true);
    }
  }, [value]);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      paddingX={6}
      paddingY={4}
      color="teal"
      boxShadow="sm"
      zIndex="sticky"
      borderRadius={10}
    >
      <Flex align="center" w="75%">
        <Button
          onClick={gotoAddStockPage}
          bgGradient="linear(to-r, teal.300,  teal.100)"
          _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
          mb={{ base: 2, md: 0 }}
        >
          Add Stock
        </Button>
        <Input
          type="text"
          placeholder="Search Stocks..."
          flex="1"
          ml={{ base: 0, md: 4 }}
          onChange={handleFilter}
          focusBorderColor="green.400"
        />
        <Button
          onClick={gotoGenerateInvoicePage}
          bgGradient="linear(to-r, teal.300,  teal.100)"
          _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
          mb={{ base: 2, md: 0 }}
          ml={{ base: 0, md: 4 }}
        >
          Invoice
        </Button>
        <Box display={{ base: "none", md: "block" }} >
          {showBox && (
            <Box
              position="absolute"
              top="70px"
              left="300px"
              right="0"
              border="1px solid purple"
              borderRadius={5}
              backgroundColor="white"
              overflow="hidden"
              zIndex="999"
              w="60%"
            >
              {stockData &&
                stockData.map((item) => (
                  <Box key={item.id} p={2}>
                    <Link to={`/stockcontrol`}>
                      <Flex justifyContent="space-between">
                        <Box textAlign="start">
                          <Text fontWeight="semibold" color="purple">
                            {item.name}
                          </Text>
                          <Text fontSize="sm">Quantity {item.quantity}</Text>
                        </Box>
                        <Box>
                          <Text fontSize="sm">Order Id : {item.order_id}</Text>
                          <Text fontSize="sm">
                            Rack Position : {item.rack_pos}
                          </Text>
                        </Box>
                      </Flex>
                    </Link>
                  </Box>
                ))}
            </Box>
          )}
        </Box>
      </Flex>
      <Spacer />
      <Box>
        <IconButton
          icon={<BellIcon />}
          ml={2}
          colorScheme="teal"
          onClick={handleNotificationClick}
          aria-label="Notifications"
        />

        <Menu>
          <MenuButton
            as={IconButton}
            icon={
              <Avatar
                size="sm"
                name="Admin"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-SQGQRq2XYAV7IP-YAjF16UYQ_eRGupVRK3Ke8m10Aw&s"
              />
            }
            colorScheme="white"
            ml={2}
          />
          <MenuList>
            <MenuItem onClick={handleForgetPassword}>Change Password</MenuItem>
            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};

export default Navbar;
