import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Alert,
  AlertIcon,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { format } from "date-fns";
import { getInvoiceDteails, seeSalesStocks } from "../../Redux/app/action";
import { Pagination } from "../../Components/Pagination";
import { Link, useNavigate, useNavigation } from "react-router-dom";

const ReturnReport = () => {
  const InputStyle = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "purple.300",
    py: "2",
    px: "4",
    variant: "unstyled",
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "purple",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };

  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(seeSalesStocks());
  }, [dispatch]);

  const salesStocks = useSelector((state) => state.AppReducer.salesStocks);
  //console.log("salesStocks", salesStocks);

  const invoiceDetails = useSelector(
    (state) => state.AppReducer.invoiceDetails
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  let perPage = 10;

  // Check if taskData is an array, otherwise initialize it as an empty array
  let salesDataArray = Array.isArray(salesStocks) ? salesStocks : [salesStocks];

  const dataWithPurchaseTotal = salesDataArray.map((el) => {
    // Find the corresponding addedStock for the current paginationData order_id

    const matchingInvoice = invoiceDetails.find(
      (invoice) => invoice.id === el.invoice_id
    );
    const invoiceNo = matchingInvoice ? matchingInvoice.invoice_no : null;
    //console.log("invoiceNo", invoiceNo);
    // Calculate the total purchase rate (purchase rate * quantity)

    // Return the original data along with the new totalPurchaseRate property
    return {
      ...el,
      invoiceNo: invoiceNo,
    };
  });

  // Calculate total pages based on the filtered data
  let totalPage = Math.ceil(dataWithPurchaseTotal.length / perPage);
  let end = page * perPage;
  let start = end - perPage;

  // Apply filtering on the taskDataArray and handle non-array cases
  let filteredData = Array.isArray(dataWithPurchaseTotal)
    ? dataWithPurchaseTotal.filter(
        (el) =>
          (el.name &&
            el.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.order_id &&
            el.order_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.created_at &&
            el.created_at.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.invoiceNo &&
            el.invoiceNo.toLowerCase().includes(searchQuery.toLowerCase()))
      )
    : [dataWithPurchaseTotal];

  // Apply pagination to the filteredData
  let paginationData = filteredData.slice(start, end);

  //console.log("paginationData", paginationData);

  // Handling Pagination End Here

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  //console.log("selectedItem", selectedItem);
  const handleOpenModal = (item) => {
    setSelectedItem(item);
    onOpen();
  };

  const [quantityreturned, setQuantityReturned] = useState("");
  //console.log("quantityreturned", quantityreturned)

  useEffect(() => {
    dispatch(getInvoiceDteails());
  }, [dispatch]);
  // const OverlayOne = ({ selectedItem, onReturn }) => {
  //   const [editedQuantity, setEditedQuantity] = useState(selectedItem.quantity);
  //   const [quantityError, setQuantityError] = useState(null);
  //   const [amountToReturn, setAmountToReturn] = useState(
  //     selectedItem.quantity * selectedItem.rate
  //   );

  //   const handleQuantityChange = (event) => {
  //     const newQuantity = parseInt(event.target.value, 10) || 0;

  //     if (newQuantity < 0 || newQuantity > selectedItem.quantity) {
  //       setQuantityError("Invalid quantity");
  //     } else {
  //       setQuantityError(null);
  //       setEditedQuantity(newQuantity);
  //       setAmountToReturn(newQuantity * selectedItem.rate);
  //       setQuantityReturned(newQuantity);
  //     }
  //   };

  //   useEffect(() => {
  //     // Update the amountToReturn when the rate changes
  //     setAmountToReturn(editedQuantity * selectedItem.rate);
  //   }, [selectedItem.rate, editedQuantity]);

  //   const invoiceDetails = useSelector(
  //     (state) => state.AppReducer.invoiceDetails
  //   );

  //   const filteredData = selectedItem.invoice_id
  //     ? invoiceDetails
  //         .filter((item) => item.id === selectedItem.invoice_id)
  //         .map((item) =>
  //           item.sales.map((sale) => ({
  //             ...sale,
  //             biller_name: item.biller_name,
  //             biller_address: item.biller_address,
  //             biller_mobile: item.biller_mobile,
  //             biller_city: item.biller_city,
  //           }))
  //         )
  //         .flat()
  //     : [];

  //   //console.log("flat", filteredData)

  //   return (
  //     <ModalOverlay
  //       bg="blackAlpha.100"
  //       backdropFilter="blur(1px) hue-rotate(90deg)"
  //     >
  //       <ModalContent>
  //         <ModalHeader>Return Item Details</ModalHeader>
  //         <ModalCloseButton />
  //         <ModalBody>
  //           <Flex justifyContent={"space-between"} gap={5}>
  //             <Box position="relative" mt={5}>
  //               <Input
  //                 {...InputStyle}
  //                 type="text"
  //                 value={
  //                   filteredData.length > 0 ? filteredData[0].biller_name : ""
  //                 }
  //                 readOnly
  //               />
  //               <Box {...BoxStyle}>Biller Name</Box>
  //             </Box>
  //             <Box position="relative" mt={5}>
  //               <Input
  //                 {...InputStyle}
  //                 type="text"
  //                 value={
  //                   filteredData.length > 0
  //                     ? filteredData[0].biller_address
  //                     : ""
  //                 }
  //                 readOnly
  //               />
  //               <Box {...BoxStyle}>Biller Address</Box>
  //             </Box>
  //           </Flex>
  //           <Flex justifyContent={"space-between"} gap={5} mb={25}>
  //             <Box position="relative" mt={5}>
  //               <Input
  //                 {...InputStyle}
  //                 type="text"
  //                 value={
  //                   filteredData.length > 0 ? filteredData[0].biller_mobile : ""
  //                 }
  //                 readOnly
  //               />
  //               <Box {...BoxStyle}>Biller Mobile</Box>
  //             </Box>
  //             <Box position="relative" mt={5}>
  //               <Input
  //                 {...InputStyle}
  //                 type="text"
  //                 value={
  //                   filteredData.length > 0 ? filteredData[0].biller_city : ""
  //                 }
  //                 readOnly
  //               />
  //               <Box {...BoxStyle}>Biller City</Box>
  //             </Box>
  //           </Flex>
  //           <Box position="relative">
  //             <Input {...InputStyle} type="text" value={selectedItem.name} />
  //             <Box {...BoxStyle}>Name</Box>
  //           </Box>
  //           <Box position="relative" mt={5}>
  //             <Input
  //               {...InputStyle}
  //               type="number"
  //               value={editedQuantity}
  //               onChange={handleQuantityChange}
  //               isInvalid={quantityError !== null}
  //             />
  //             <Box {...BoxStyle}>Quantity Returned</Box>
  //           </Box>
  //           <Box position="relative" mt={5}>
  //             <Input {...InputStyle} type="number" value={amountToReturn} />
  //             <Box {...BoxStyle}>Amount to Return in Rs</Box>
  //           </Box>

  //           <FormErrorMessage>{quantityError}</FormErrorMessage>
  //         </ModalBody>
  //         <ModalFooter>
  //           <Button
  //             onClick={() =>
  //               onReturn({ ...selectedItem, quantity: editedQuantity })
  //             }
  //             bgGradient="linear(to-r, purple.300, purple.100)"
  //             _hover={{ bgGradient: "linear(to-r, purple.100, purple.400)" }}
  //             isDisabled={quantityError !== null}
  //             size={"sm"}
  //           >
  //             Return
  //           </Button>
  //         </ModalFooter>
  //       </ModalContent>
  //     </ModalOverlay>
  //   );
  // };

  // const handleReturn = () => {
  //   const requestData = {
  //     quantity: quantityreturned,
  //   };
  //   fetch(
  //     `https://bharatapi.exploreanddo.com/api/return-stock/${selectedItem.id}`,
  //     {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestData),
  //     }
  //   )
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Failed to update item");
  //       }
  //       return response.json();
  //     })
  //     .then((updatedData) => {
  //       //console.log('Item updated successfully:', updatedData);
  //       toast({
  //         position: "top",
  //         title: "Item Returned Successfully",
  //         description: `Your Item has been returned successfully`,
  //         status: "success",
  //         duration: 3000,
  //         isClosable: true,
  //       });

  //       setTimeout(() => {
  //         // window.location.reload();
  //         navigate("/returnedItems")
  //       }, 3000);
  //       onClose();
  //     })
  //     .catch((error) => {
  //       //console.error('Error updating item:', error);
  //       toast({
  //         position: "top",
  //         title: error,
  //         description: "",
  //         status: "error",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     });
  // };

  const OverlayOne = ({ selectedItem, onReturn }) => {
    const [editedQuantity, setEditedQuantity] = useState(quantityreturned);
    const [quantityError, setQuantityError] = useState(null);
    const [amountToReturn, setAmountToReturn] = useState(
      selectedItem.quantity * selectedItem.rate
    );

    const handleQuantityChange = (event) => {
      const newQuantity = parseInt(event.target.value, 10) || 0;

      if (newQuantity < 0 || newQuantity > selectedItem.quantity) {
        setQuantityError("Invalid quantity");
      } else {
        setQuantityError(null);
        setEditedQuantity(newQuantity);
        setAmountToReturn(newQuantity * selectedItem.rate);
        setQuantityReturned(newQuantity);
      }
    };

    useEffect(() => {
      setAmountToReturn(editedQuantity * selectedItem.rate);
    }, [selectedItem.rate, editedQuantity]);

    const invoiceDetails = useSelector(
      (state) => state.AppReducer.invoiceDetails
    );

    const filteredData = selectedItem.invoice_id
      ? invoiceDetails
          .filter((item) => item.id === selectedItem.invoice_id)
          .map((item) =>
            item.sales.map((sale) => ({
              ...sale,
              biller_name: item.biller_name,
              biller_address: item.biller_address,
              biller_mobile: item.biller_mobile,
              biller_city: item.biller_city,
            }))
          )
          .flat()
      : [];

    return (
      <ModalOverlay
        bg="blackAlpha.100"
        backdropFilter="blur(1px) hue-rotate(90deg)"
      >
        <ModalContent>
          <ModalHeader>Return Item Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent={"space-between"} gap={5}>
              <Box position="relative" mt={5}>
                <Input
                  {...InputStyle}
                  type="text"
                  value={
                    filteredData.length > 0 ? filteredData[0].biller_name : ""
                  }
                  readOnly
                />
                <Box {...BoxStyle}>Biller Name</Box>
              </Box>
              <Box position="relative" mt={5}>
                <Input
                  {...InputStyle}
                  type="text"
                  value={
                    filteredData.length > 0
                      ? filteredData[0].biller_address
                      : ""
                  }
                  readOnly
                />
                <Box {...BoxStyle}>Biller Address</Box>
              </Box>
            </Flex>
            <Flex justifyContent={"space-between"} gap={5} mb={25}>
              <Box position="relative" mt={5}>
                <Input
                  {...InputStyle}
                  type="text"
                  value={
                    filteredData.length > 0 ? filteredData[0].biller_mobile : ""
                  }
                  readOnly
                />
                <Box {...BoxStyle}>Biller Mobile</Box>
              </Box>
              <Box position="relative" mt={5}>
                <Input
                  {...InputStyle}
                  type="text"
                  value={
                    filteredData.length > 0 ? filteredData[0].biller_city : ""
                  }
                  readOnly
                />
                <Box {...BoxStyle}>Biller City</Box>
              </Box>
            </Flex>
            <Box position="relative">
              <Input {...InputStyle} type="text" value={selectedItem.name} />
              <Box {...BoxStyle}>Name</Box>
            </Box>
            <Box position="relative" mt={5}>
              <Input
                {...InputStyle}
                type="number"
                value={selectedItem.quantity}
                readOnly
              />
              <Box {...BoxStyle}>Quantity Availabe For Return</Box>
            </Box>
            <Box position="relative" mt={5}>
              <Input
                {...InputStyle}
                type="number"
                value={editedQuantity}
                onChange={handleQuantityChange}
                isInvalid={quantityError !== null}
              />
              <Box {...BoxStyle}>Quantity Returned</Box>
            </Box>
            <Box position="relative" mt={5}>
              <Input
                {...InputStyle}
                type="number"
                value={amountToReturn}
                readOnly
              />
              <Box {...BoxStyle}>Amount to Return in Rs</Box>
            </Box>

            <FormErrorMessage>{quantityError}</FormErrorMessage>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() =>
                onReturn({ ...selectedItem, quantity: editedQuantity })
              }
              bgGradient="linear(to-r, purple.300, purple.100)"
              _hover={{ bgGradient: "linear(to-r, purple.100, purple.400)" }}
              isDisabled={quantityError !== null}
              size={"sm"}
            >
              Return
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    );
  };

  const handleReturn = () => {
    const parsedQuantityReturned = parseInt(quantityreturned, 10);
    if (!isNaN(parsedQuantityReturned)) {
      const requestData = {
        quantity: parsedQuantityReturned,
      };

      fetch(
        `https://bharatapi.exploreanddo.com/api/return-stock/${selectedItem.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to update item");
          }
          return response.json();
        })
        .then((updatedData) => {
          toast({
            position: "top",
            title: "Item Returned Successfully",
            description: `Your Item has been returned successfully`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          setTimeout(() => {
            navigate("/returnedItems");
            window.location.reload();
          }, 3000);
          onClose();
        })
        .catch((error) => {
          toast({
            position: "top",
            title: error.message,
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    } else {
      toast({
        position: "top",
        title: "Invalid return quantity",
        description: "Please enter a valid quantity",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex>
      <Box pl={{ base: "none", md: "10" }} w={{ base: "100%", md: "100%" }}>
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Return Report
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">Return Report</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justifyContent={"space-between"} gap={3}>
            <Link to="/returns">
              <Button
                size={"md"}
                bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{
                  bgGradient: "linear(to-r, teal.100,  teal.400)",
                }}
              >
                Go to Return
              </Button>
            </Link>
            <Link to="/returnedItems">
              <Button
                size={"md"}
                bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{
                  bgGradient: "linear(to-r, teal.100,  teal.400)",
                }}
              >
                See Returned Items
              </Button>
            </Link>
            <Input
              htmlSize={20}
              width={500}
              placeholder="Search By Invoice Number To Return"
              focusBorderColor="green.400"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Flex>
        </Flex>
        <Box>
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Return
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Invoice No
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Bill Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Name
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    HSN/SAC
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Quantity
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Rate
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Total Amount
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationData.length > 0 &&
                  paginationData
                    ?.filter((el) => el.quantity > 0)
                    .map((el) => {
                      return (
                        <Tr key={el.id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              onClick={() => handleOpenModal(el)}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              Return
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.invoiceNo}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {format(new Date(el.created_at), "dd-MM-yyyy")}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.name}
                            </Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.order_id}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.quantity}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              Rs {el.rate}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              Rs {el.amount}
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
              </Tbody>
              {isOpen && selectedItem && (
                <Modal isOpen={isOpen} onClose={onClose}>
                  <OverlayOne
                    selectedItem={selectedItem}
                    onReturn={handleReturn}
                  />
                </Modal>
              )}
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Matches Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default ReturnReport;
