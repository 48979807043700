import React, { useState } from "react";
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Box,
    Flex,
    useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ChangePassword = () => {
    const [old_password, setOldPassword] = useState("");
    const [new_password, setNewPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const storedToken = localStorage.getItem("token")
    //console.log("storedToken", storedToken)
    const navigate = useNavigate();
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    const handleChangePassword = async () => {
        if (new_password !== confirm_password) {
            toast({
                position: "top",
                title: "Password Mismatch",
                description: "New Password and Confirm Password must match",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        try {
            setLoading(true);
            const storedToken = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${storedToken}`,
            };

            //console.log("Request headers", headers);
            //const response = await axios.post("https://bharatapi.exploreanddo.com/api/change-password", {}, { headers });
            const response = await axios.post("https://bharatapi.exploreanddo.com/api/change-password", {
                old_password: old_password,
                new_password: new_password,
                confirm_password: confirm_password,
            }, { headers });

            if (response.data.success) {
                toast({
                    position: "top",
                    title: "Password Changed Successfully",
                    description: "Logging Out. Login With New Password",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                localStorage.removeItem('state');
                localStorage.removeItem("token");
                setTimeout(() => {
                    navigate("/");
                }, 3000);
            } else {
                handleErrorResponse(response.data);
            }
        } catch (error) {
            handleErrorResponse(error);
        } finally {
            setLoading(false);
        }
    }

    const handleErrorResponse = (error) => {
        console.error("Password Change Failed:", error);
        toast({
            position: "top",
            title: "Password Change Failed",
            description: error.message || "An error occurred",
            status: "error",
            duration: 3000,
            isClosable: true,
        });
    }


    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleChangePassword();
        }
    };

    return (
        <Flex
            minHeight="100vh"
            alignItems="center"
            justifyContent="center"
            px={{ base: 4, sm: 6, md: 8 }}
        >
            <VStack spacing={4} align="stretch" w="100%" maxW="md">
                <Box p={8} borderWidth={1} borderRadius={8} boxShadow="lg" w="100%">
                    <FormControl id="old_password">
                        <FormLabel>Old Password</FormLabel>
                        <Input
                            type="password"
                            placeholder="Enter your old password"
                            value={old_password}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                            onKeyPress={handleKeyPress}
                        />
                    </FormControl>
                    <FormControl mt={4} id="new_password">
                        <FormLabel>New Password</FormLabel>
                        <Input
                            type="password"
                            placeholder="Enter your new password"
                            value={new_password}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            onKeyPress={handleKeyPress}
                        />
                    </FormControl>
                    <FormControl mt={4} id="confirm_password">
                        <FormLabel>Confirm New Password</FormLabel>
                        <Input
                            type="password"
                            placeholder="Confirm your new password"
                            value={confirm_password}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            onKeyPress={handleKeyPress}
                        />
                    </FormControl>

                    {loading ? (<Button
                        mt={4}
                        onClick={handleChangePassword}
                        bgGradient="linear(to-r, teal.300,  teal.100)"
                        _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                        w="100%"
                        isLoading
                        loadingText='Password Changing'
                    >Change Password
                    </Button>) : (<Button
                        mt={4}
                        onClick={handleChangePassword}
                        bgGradient="linear(to-r, teal.300,  teal.100)"
                        _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                        w="100%"
                    >
                        Change Password
                    </Button>)}
                </Box>
            </VStack>
        </Flex>
    );
};

export default ChangePassword;
