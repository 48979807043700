import React from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiOutlineStock } from "react-icons/ai";
import { FcSalesPerformance } from "react-icons/fc";
import { TbReportSearch } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { MdHome } from "react-icons/md";
import { MdAssignmentReturn } from "react-icons/md";
const Sidebar = () => {
  const navigate = useNavigate();
  const gotoHomePage = () => {
    navigate("/dashboard");
  };

  const gotoStockPage = () => {
    navigate("/stockcontrol");
  };
  const gotoSalesPage = () => {
    navigate("/sales");
  };
  const gotoReturnPage = () => {
    navigate("/returns");
  };
  const gotoReportPage = () => {
    navigate("/reports");
  };
  return (
    <Box
      as="nav"
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={useColorModeValue("lg", "dark-lg")}
      transition="width 0.5s ease"
      borderRadius={10}
      height="100vh"
    >
      <VStack align="start" spacing={4} p={4} mt={20}>
        <Button
          size={"sm"}
          color="green"
          bgGradient="linear(to-r, teal.300,  teal.100)"
          _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
          onClick={gotoHomePage}
          p={8}
        >
          <Flex flexDirection={"column"}>
            <MdHome size={30} />
            <Text>Home</Text>
          </Flex>
        </Button>
        <Button
          size={"sm"}
          color="red"
          bgGradient="linear(to-r, teal.300,  teal.100)"
          _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
          onClick={gotoStockPage}
          p={8}
        >
          <Flex flexDirection={"column"}>
            <AiOutlineStock size={30} />
            <Text>Stock</Text>
          </Flex>
        </Button>
        <Button
          size={"sm"}
          color="black"
          bgGradient="linear(to-r, teal.300,  teal.100)"
          _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
          onClick={gotoSalesPage}
          p={8}
        >
          <Flex flexDirection={"column"}>
            <FcSalesPerformance size={30} />
            <Text>Sales</Text>
          </Flex>
        </Button>
        <Button
          size={"sm"}
          color="black"
          bgGradient="linear(to-r, teal.300,  teal.100)"
          _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
          onClick={gotoReturnPage}
          p={8}
        >
          <Flex flexDirection={"column"}>
            <MdAssignmentReturn size={30} />
            <Text>Return</Text>
          </Flex>
        </Button>
        <Button
          size={"sm"}
          color="blue"
          bgGradient="linear(to-r, teal.300,  teal.100)"
          _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
          onClick={gotoReportPage}
          p={8}
        >
          <Flex flexDirection={"column"}>
            <TbReportSearch size={30} />
            <Text>Report</Text>
          </Flex>
        </Button>
      </VStack>
    </Box>
  );
};

export default Sidebar;
